import { AccordionLayout } from './TermsAndDescriptionsLayouts/Accordion'
import { TableLayout } from './TermsAndDescriptionsLayouts/Table'
import { TwoColumnsLayout } from './TermsAndDescriptionsLayouts/TwoColumns'

export interface ITermsAndDescriptions {
  term: string
  description: string
}

export type TDesignComponent = 'table' | 'accordion' | '2columns'

export interface IConfigurableTermsAndDescriptions {
  designComponent: TDesignComponent
  termsAndDescriptionsList: ITermsAndDescriptions[]
  layout: 'row' | 'column'
}

const designLayoutMapper = {
  accordion: AccordionLayout,
  table: TableLayout,
  '2columns': TwoColumnsLayout,
}

export function ConfigurableTermsAndDescriptions({
  designComponent,
  termsAndDescriptionsList,
  layout,
}: IConfigurableTermsAndDescriptions) {
  const Component = designLayoutMapper[designComponent]
  return (
    !!Component && (
      <Component
        termsAndDescriptionsList={termsAndDescriptionsList}
        layout={layout}
      />
    )
  )
}
