import classNames from 'classnames'
import React from 'react'

interface ITableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  responsive?: boolean | 'one-col'
  striped?: boolean
  bordered?: 'none' | 'all' | 'no-col'
  hover?: boolean
  textAlign?: 'left' | 'center' | 'right'
}

export const Table = React.forwardRef<HTMLTableElement, ITableProps>(
  (
    {
      className,
      responsive = true,
      bordered = 'none',
      hover = false,
      striped = false,
      textAlign = 'left',
      ...props
    },
    ref
  ) => {
    const classes = classNames(
      className,
      striped && `table-striped`,
      bordered !== 'none' && `table-bordered-${bordered}`,
      hover && `table-hover`,
      textAlign && `table-text-align-${textAlign}`
    )

    const table = <table {...props} className={classes} ref={ref} />

    if (responsive) {
      let responsiveClass = `table-responsive`
      if (typeof responsive === 'string') {
        responsiveClass = `${responsiveClass}-${responsive}`
      }

      return <div className={`table ${responsiveClass}`}>{table}</div>
    }

    return table
  }
)
