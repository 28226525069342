import { useInView } from 'react-intersection-observer'
import {
  ConfigurableTermsAndDescriptions,
  TDesignComponent,
  ITermsAndDescriptions,
} from './ConfigurableTermsAndDescriptions'
import { subtitleSizeToClass, titleSizeToClass } from '../contentfulPage.utils'
import { TContentfulConfigurableCta } from 'types/contentful/configurableCta.types'
import { ConfigurableCta } from '../ConfigurableCta'
import { useDevice } from '@utils/responsiveness/useDevice'
import { ComponentPlaceholder } from '../ComponentPlaceholder'
import { mapTextColorToClass } from '@components/ContentfulPage/ConfigurableText/ConfigurableText.utils'

export interface IConfigurableDescriptionList {
  title: string
  titleColor?: string
  titleSize?: string
  subTitle?: string
  subtitleColor?: string
  subtitleSize?: 'XS' | 'S' | 'M' | 'L' | 'XL'
  submenuLabel?: string
  designComponent?: TDesignComponent
  layout: 'column' | 'row'
  termsAndDescriptionsList?: ITermsAndDescriptions[]
  footerNote?: string
  cta: TContentfulConfigurableCta
  backgroundColor: string
  generateStatically?: boolean
}

export default function ConfigurableDescriptionList({
  title,
  titleColor = 'purple',
  titleSize = 'L',
  subTitle,
  subtitleColor = 'grey',
  subtitleSize = 'S',
  designComponent,
  layout = 'column',
  termsAndDescriptionsList,
  footerNote,
  cta,
  backgroundColor = '#FFFFFF',
  generateStatically = false,
}: IConfigurableDescriptionList) {
  const { isMobile, isTablet } = useDevice()
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  })

  const mapElementsClassesToLayout = {
    column: {
      wrapper: 'flex-col px-8 py-[32px] md:px-12 lg:px-32 justify-center',
      title: `font-bold md:mb-24 text-center mb-10 order-2 ${
        mapTextColorToClass[titleColor] || mapTextColorToClass.purple
      }`,
      subtitle: `font-semibold text-center order-1 ${
        mapTextColorToClass[subtitleColor] || mapTextColorToClass.grey
      }`,
    },
    row: {
      wrapper:
        'flex-col lg:flex-row py-32 justify-between w-full max-w-[1200px] mx-auto px-12 xl:px-0 gap-x-32',
      title: `font-bold text-left mb-10 order-1 text-acai font-mori ${
        mapTextColorToClass[titleColor] || mapTextColorToClass.acai
      }`,
      subtitle: `font-regular text-left order-2 mb-12 lg:mb-20 font-mori text-core-grey-90 ${
        mapTextColorToClass[subtitleColor] || mapTextColorToClass.acai
      }`,
    },
  }

  const componentTypeToPlaceholderHeight: Record<TDesignComponent, number> = {
    '2columns': 510,
    accordion: 820,
    table: 850,
  }

  return (
    <div
      ref={ref}
      className={`relative w-full bg-core-white text-core-purple`}
      style={{ backgroundColor: backgroundColor }}
    >
      {generateStatically || inView ? (
        <div className={`flex ${mapElementsClassesToLayout[layout].wrapper}`}>
          <div
            className={`flex flex-col ${
              layout === 'row' ? 'items-start' : 'items-center'
            }`}
          >
            {subTitle && (
              <h4
                className={`${subtitleSizeToClass[subtitleSize]} ${mapElementsClassesToLayout[layout].subtitle}`}
              >
                {subTitle}
              </h4>
            )}

            {title && (
              <h3
                className={`font-bold ${
                  titleSizeToClass[titleSize] || titleSizeToClass.L
                } ${mapElementsClassesToLayout[layout].title}`}
              >
                {title}
              </h3>
            )}

            {!isMobile && !isTablet && cta && (
              <ConfigurableCta
                {...cta}
                onClick={() => {
                  console.log('click')
                }}
                className="order-3"
              />
            )}
          </div>

          <div className="flex justify-center">
            <div
              className={`${
                designComponent === '2columns' ? '' : 'max-w-[874px]'
              }`}
            >
              <ConfigurableTermsAndDescriptions
                designComponent={designComponent}
                termsAndDescriptionsList={termsAndDescriptionsList}
                layout={layout}
              />

              {footerNote && (
                <div
                  className={`p-6 text-xl text-core-purple bg-core-purple-10 ${
                    designComponent === 'table'
                      ? 'rounded-b-md shadow-[0_0_0_2px_var(--core-purple-10)]'
                      : 'rounded-md'
                  }`}
                >
                  {footerNote}
                </div>
              )}
            </div>
          </div>

          {(isMobile || isTablet) && cta && (
            <ConfigurableCta
              {...cta}
              onClick={() => {
                console.log('click')
              }}
              className="order-3"
            />
          )}
        </div>
      ) : (
        <ComponentPlaceholder
          height={componentTypeToPlaceholderHeight[designComponent]}
          bgColor={backgroundColor}
        />
      )}
    </div>
  )
}
