import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { ITermsAndDescriptions } from '../ConfigurableTermsAndDescriptions'

export const TwoColumnsLayout = ({
  termsAndDescriptionsList,
}: {
  termsAndDescriptionsList?: ITermsAndDescriptions[]
}) => {
  if (termsAndDescriptionsList?.length < 1) {
    return null
  }

  return (
    <div className="grid px-8 py-6 mb-16 gap-x-8 md:grid-cols-2">
      {termsAndDescriptionsList?.map(({ term, description }, index) => (
        <div className="mb-10 text-core-purple" key={`${term}_${index}`}>
          <span className="mb-4 text-2xl font-bold">{term}</span>
          <ContentfulMarkdown>{description}</ContentfulMarkdown>
        </div>
      ))}
    </div>
  )
}
