import { Table } from '@components/common/Table/Table'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { ITermsAndDescriptions } from '../ConfigurableTermsAndDescriptions'

export const TableLayout = ({
  termsAndDescriptionsList,
}: {
  termsAndDescriptionsList?: ITermsAndDescriptions[]
}) => {
  if (termsAndDescriptionsList?.length < 1) {
    return null
  }

  return (
    <Table bordered="no-col">
      <tbody>
        {termsAndDescriptionsList?.map(({ term, description }) => (
          <tr key={term}>
            <th>{term}</th>
            <td>
              <ContentfulMarkdown>{description}</ContentfulMarkdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
