export function ComponentPlaceholder({
  height,
  bgColor,
}: {
  height: number
  bgColor?: string
}) {
  return (
    <div
      style={{
        height: `${height || 400}px`,
        backgroundColor: `${bgColor || '#DDDDDD'}`,
      }}
    />
  )
}
