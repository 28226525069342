import { Accordion, AccordionItem } from '@noissue-ui-kit/oldBranding/accordion'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { ITermsAndDescriptions } from '../ConfigurableTermsAndDescriptions'

export interface AccordionLayout {
  termsAndDescriptionsList: ITermsAndDescriptions[]
  layout?: 'row' | 'column'
}

export function AccordionLayout({
  termsAndDescriptionsList,
  layout,
}: AccordionLayout) {
  const mapElementsClassToLayout = {
    column: {
      label: 'py-6 text-core-purple',
      content: 'py-6 text-core-purple',
    },
    row: {
      label: 'py-2 leading-snug text-2xl md:text-3xl font-mori text-acai',
      content: 'py-6 text-boulder',
    },
  }

  return (
    <Accordion initialMode="showFirst" className="mb-16 lead">
      {termsAndDescriptionsList?.map(({ term, description }, index) => (
        <AccordionItem
          key={`${term}_${index}`}
          labelClassName={`font-semibold ${mapElementsClassToLayout[layout].label}`}
          label={term}
        >
          <div>
            <ContentfulMarkdown
              overrideClass={`${mapElementsClassToLayout[layout].content}`}
              overrideStyles={{
                a: ({ children, href }) => (
                  <a
                    className="pointer-events-auto text-boulder font-bold"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {` ${children} `}
                  </a>
                ),
              }}
            >
              {description}
            </ContentfulMarkdown>
          </div>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
